/* AwardsCarousel.css */

.awards-section {
  padding: 2rem;
  background-color: #f9f9f9;
  text-align: center;
  max-width: 1000px;
  background-color: #00000000;
}

.awards-section h2 {
  font-size: 2rem;
  margin-bottom: 1.5rem;
}

.awards-carousel {
  position: relative;
}

.award-card {
  transition: transform 0.5s ease, opacity 0.5s ease;
  padding: 0.3rem;
  max-width: 300px;
}

.award-card.small {
  transform: scale(0.7);
  opacity: 0.7;
}

.award-card.active {
  transform: scale(1);
  opacity: 1;
}

.main-award-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.small-award-image {
  width: 80%;
  height: auto;
  border-radius: 10px;
  opacity: 0.8;
  filter: grayscale(50%);
}

.main-award-info {
  margin-top: 1rem;
  font-size: 1.2rem;
  color: #333;
  text-align: center;
}

.award-name {
  font-weight: bold;
  font-size: 1.5rem;
  color: #ffffff;
}

.award-prize {
  font-size: 2rem;
  font-weight: 800;
  background: linear-gradient(90deg, #fff4e0, #ffd700);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
}

.slick-prev,
.slick-next {
  width: 40px;
  height: 40px;
  z-index: 1;
}

.slick-prev:before,
.slick-next:before {
  font-size: 40px;
  color: #333;
}

.slick-dots li button:before {
  color: #000;
  opacity: 0.5;
}

.slick-dots li.slick-active button:before {
  opacity: 1;
}


