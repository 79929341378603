/* src/styles/Clases.css */

/* Contenedor principal */
.clases-container {
    padding: 2rem;
    text-align: center;
    background-color: #1b1b2f;
    color: #ffffff;
    min-height: 100vh;
  }
  
  .clases-container h2 {
    font-size: 2.5rem;
    margin-bottom: 2rem;
  }
  
  /* Grid de clases */
  .clases-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
  }
  
  .clase-card {
    background-color: #162447;
    padding: 1.5rem;
    border-radius: 10px;
    width: 250px;
    cursor: pointer;
    transition: transform 0.3s, background-color 0.3s;
  }
  
  .clase-card:hover {
    transform: scale(1.05);
    background-color: #1f4068;
  }
  
  .clase-card h3 {
    font-size: 1.5rem;
  }
  
  /* Modal de video */
  .video-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .video-content {
    position: relative;
    width: 80%;
    max-width: 800px;
    background-color: #0f0f1a;
    padding: 2rem;
    border-radius: 10px;
  }
  
  .close-button {
    position: absolute;
    top: 1rem;
    right: 1.5rem;
    font-size: 2rem;
    cursor: pointer;
    color: #ffffff;
  }
  
  .video-wrapper {
    position: relative;
    padding-bottom: 56.25%; /* Relación de aspecto 16:9 */
    height: 0;
  }
  
  .video-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  /* Estilos para la imagen de la clase */
.clase-thumbnail {
  width: 100%;
  height: auto;
  border-radius: 10px;
  margin-bottom: 1rem;
}

/* Opcional: Ajustes al .clase-card */
.clase-card {
  background-color: #162447;
  padding: 1rem;
  border-radius: 10px;
  width: 250px;
  cursor: pointer;
  transition: transform 0.3s, background-color 0.3s;
  overflow: hidden; /* Para asegurar que el contenido no se desborde */
}

.clase-card h3 {
  font-size: 1.2rem;
  margin-top: 0.5rem;
} 