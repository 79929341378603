/* src/styles/Login.css */

/* Estilos generales */
body {
    background-color: #1b1b2f;
    color: #ffffff;
    font-family: 'Open Sans', sans-serif;
  }
  
  /* Contenedor principal */
  .login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80vh;
  }
  
  .login-container h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  /* Formulario */
  .login-form {
    background-color: #162447;
    padding: 40px;
    border-radius: 10px;
    width: 100%;
    max-width: 400px;
  }
  
  .login-input {
    width: 100%;
    padding: 15px;
    margin-bottom: 20px;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
  }
  
  .login-input::placeholder {
    color: #a0a0a0;
  }
  
  .login-button {
    width: 100%;
    padding: 15px;
    background-color: #ff6347;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    font-size: 1.2rem;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .login-button:hover {
    background-color: #e5533c;
  }
  
  /* Mensaje de error */
  .login-error {
    color: #ff4d4d;
    margin-top: 10px;
    text-align: center;
  }
  