/* src/styles/Navbar.css */

/* Estilos generales para la información del usuario */
.usuario-info {
    margin-left: 1rem;
    display: flex;
    align-items: center;
    font-weight: bold;
  }
  
  /* Estilos específicos para cada rango */
  .rango-bronce {
    color: #cd7f32; /* Color bronce */
  }
  
  .rango-plata {
    color: #c0c0c0; /* Color plata */
  }
  
  .rango-oro {
    color: #ffd700; /* Color oro */
  }
  
  /* Efectos adicionales */
  .rango-bronce::after,
  .rango-plata::after,
  .rango-oro::after {
    content: '';
    display: inline-block;
    margin-left: 0.5rem;
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }
  
  .rango-bronce::after {
    background-color: #cd7f32;
  }
  
  .rango-plata::after {
    background-color: #c0c0c0;
  }
  
  .rango-oro::after {
    background-color: #ffd700;
  }
  