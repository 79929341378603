/* src/styles/LandingPage.css */

/* General Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
}

body {
  background-color: #1b1b2f;
  color: #ffffff;
  line-height: 1.6;
}

/* Encabezado */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #0b3d91;
}

.logo {
  font-size: 1.5rem;
  font-weight: bold;
  color: #ffffff;
}

.navbar {
  display: flex;
  gap: 20px;
}

.nav-link {
  color: #ffffff;
  text-decoration: none;
  font-size: 1rem;
  transition: color 0.3s;
}

.nav-link:hover {
  color: #00bcd4;
}

.nav-button {
  background-color: #00bcd4;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1rem;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.nav-button:hover {
  background-color: #008c9e;
}

/* Sección de Bienvenida */
.hero-section {
  background-image: url('../img/fondo1.jpg');
  background-size: cover;
  background-position: center;
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 20px;
}

.awardsSize{
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero-content {
  background: rgba(0, 0, 0, 0.7);
  padding: 40px;
  border-radius: 10px;
}

.hero-content h1 {
  font-size: 3rem;
  margin-bottom: 20px;
  color: #ffffff;
}

.hero-content p {
  font-size: 1.2rem;
  margin-bottom: 30px;
}

.cta-button {
  background-color: #ff6347;
  color: #ffffff;
  border: none;
  padding: 15px 30px;
  cursor: pointer;
  font-size: 1.2rem;
  border-radius: 8px;
  transition: background-color 0.3s;
}

.cta-button:hover {
  background-color: #e5533c;
}

/* Sección de Beneficios */
.benefits-section {
  padding: 60px 20px;
  text-align: center;
  background-color: #162447;
}

.benefits-section h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.benefits-section p {
  font-size: 1.1rem;
  margin-bottom: 40px;
}

.benefits-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
}

.benefit-item {
  background: #1f4068;
  padding: 20px;
  border-radius: 10px;
  width: 300px;
  text-align: center;
  transition: transform 0.3s;
}

.benefit-item:hover {
  transform: scale(1.05);
}

.benefit-item img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  margin-bottom: 20px;
}

.secondary-button {
  background-color: #00bcd4;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1rem;
  border-radius: 8px;
  transition: background-color 0.3s;
}

.secondary-button:hover {
  background-color: #008c9e;
}

/* Sección de Premios */
.awards-section {
  padding: 60px 20px;
  background-color: #1b1b2f;
  text-align: center;
}

.awards-section h2 {
  font-size: 2.5rem;
  margin-bottom: 40px;
}

.awards-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
}

.award-card {
  background: #162447;
  padding: 20px;
  border-radius: 10px;
  width: 250px;
  text-align: center;
  transition: transform 0.3s;
}

.award-card:hover {
  transform: scale(1.05);
}

.award-card img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  margin-bottom: 15px;
}

/* Sección de Contacto */
.contact-section {
  padding: 60px 20px;
  text-align: center;
  background-color: #0b3d91;
}

.contact-section h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.contact-section p {
  font-size: 1.2rem;
  margin-bottom: 30px;
}

/* Pie de Página */
.footer {
  background-color: #0b3d91;
  padding: 20px;
  text-align: center;
  color: #ffffff;
  font-size: 1rem;
}

/* StreamersSection.css */

.streamers-section {
  padding: 2rem;
  background-color: #5a10c9; /* Twitch-inspired purple */
  color: #fff;
  text-align: center;
}

.streamers-section h2 {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  color: #fff;
}

.streamer-card {
  display: inline-block;
  margin: 1.5rem;
  text-align: center;
  background: #4b367c00; /* Darker purple for contrast */
  padding: 1rem;
  border-radius: 15px;
  margin-left: 50px;

}

.streamer-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
}

.streamer-name {
  margin-top: 1.5rem;
  font-size: 1.5rem;
  font-weight: bold;
  color: #ffffff;
}

.twitch-video {
  margin-top: 3rem;
  text-align: center;
}

.twitch-video iframe {
  width: 100%;
  max-width: 800px;
  height: 450px;
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  border-radius: 15px;
}

/* AwardsCarousel.css */

.awards-section {
  padding: 2rem;
  background-color: #f9f9f9;
  text-align: center;
}

.awards-section h2 {
  font-size: 2rem;
  margin-bottom: 1.5rem;
}

.awards-carousel {
  position: relative;
}

.award-card {
  transition: transform 0.5s ease, opacity 0.5s ease;
  padding: 0.5rem;
  max-width: 300px;
  margin: 0 -10px;
}

.award-card.small {
  transform: scale(0.8);
  opacity: 0.8;
}

.award-card.active {
  transform: scale(1.1);
  opacity: 1;
}

.main-award-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.small-award-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
  filter: grayscale(50%);
}

.main-award-info {
  margin-top: 1rem;
  font-size: 1.2rem;
  color: #333;
  text-align: center;
  background: linear-gradient(to bottom, #f5f5f5, #e6e6e6);
  padding: 1rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.award-name {
  font-weight: bold;
  font-size: 1.5rem;
  color: #222;
}

.award-prize {
  font-size: 2rem;
  font-weight: 800;
  background: linear-gradient(30deg, #fff4e0, #c43684);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
}

.slick-prev,
.slick-next {
  width: 40px;
  height: 40px;
  z-index: 1;
}

.slick-prev:before,
.slick-next:before {
  font-size: 40px;
  color: #333;
}

.slick-dots li button:before {
  color: #000;
  opacity: 0.5;
}

.slick-dots li.slick-active button:before {
  opacity: 1;
}

/* Ensure the center slide starts as the main one */
.slick-center .award-card {
  transform: scale(1.1);
  opacity: 1;
  margin: 0 -10px;
}

/* Streamer image flip effect */
.streamer-card {
  perspective: 1000px;
}

.streamer-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.streamer-card:hover .streamer-image {
  transform: rotateY(180deg);
}

.streamer-image::before {
  content: url('../img/club-logo.png'); /* Logo del club */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  backface-visibility: hidden;
  transform: rotateY(180deg);
}

/* Agrega este CSS adicional o modifícalo en tu archivo existente */

/* Contenedor de la imagen y el marco */
.streamer-image-container {
  position: relative;
  width: 150px;
  height: 150px;
  margin: 0 auto; /* Centra el contenedor */
}

/* Imagen del marco */
.streamer-frame {
  position: absolute;
  top: -35%;
  left: -40%;
  width: 170%;
  height: 170%;
  pointer-events: none; /* Permite hacer clic en la imagen debajo */
}

/* Opcional: Ajustes para el nombre del streamer */
.streamer-name {
  margin-top: 1.8rem;
  margin-right: 2rem;
  font-size: 1.5rem;
  font-weight: bold;
  color: #ffffff;
}

/* Sección de Redes Sociales */
.social-media-section {
  padding: 60px 20px;
  text-align: center;
  background-color: #162447; /* Puedes ajustar el color según tu preferencia */
}

.social-media-section h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #ffffff;
}

.social-media-section p {
  font-size: 1.2rem;
  margin-bottom: 30px;
  color: #ffffff;
}

.social-media-icons {
  display: flex;
  justify-content: center;
  gap: 40px;
}

.social-media-icons a img {
  width: 60px;
  height: 60px;
  transition: transform 0.3s;
}

.social-media-icons a img:hover {
  transform: scale(1.2);
}

